<template>
    <v-row>
        <v-col cols="12">
            <v-btn :loading="carregando" @click="salvar" :disabled="carregando" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Visão geral</v-toolbar-title>
                </v-toolbar>
                <v-progress-linear top absolute indeterminate color="#F44336" :active="carregando" />
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" md="4"><v-file-input accept="image/jpg" hide-details label="* Foto (JPG):" @change="adicionarArquivo" v-model="registro.placeholder" /></v-col>
                            <v-col cols="12" class="pb-0 mt-3" v-if="erro"><v-alert dense type="error" v-html="erro"/></v-col>
                            <v-col cols="12" class="pb-0 mt-3" v-if="sucesso"><v-alert dense type="success" v-html="sucesso"/></v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import axios from 'axios'
    import {mapState} from 'vuex'

    export default {
        name: "UsuarioSenhaForm",
        data() {
            return {
                registro : {},
                erro : null,
                sucesso : null,
                migalhaPao : [],
                carregando : false
            }
        },
        computed : {
            ...mapState(['apiUrl'])
        },
        methods : {
            adicionarArquivo(arquivo) {
                let reader = new FileReader()
                reader.readAsDataURL(arquivo)
                let that = this
                reader.onload = function () {
                    that.registro.arquivo = reader.result.split('base64,')[1]
                }
            },
            salvar() {
                this.carregando = true
                return axios.post(`${this.apiUrl}usuario/imagem/alterar`, this.registro).then( () => {
                    this.registro = {}
                    this.carregando = false
                    location.reload()
                }).catch(() => {
                    this.registro = {}
                    this.carregando = false
                })
            }
        },
        activated : function() {
            this.registro = {}
        }
    }
</script>

<style scoped>

</style>
